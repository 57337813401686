import * as React from "react";
import "antd/dist/antd.css";
import "../../assets/styles.scss";
import Main from "../../layout/layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Row, Col } from "antd";
import SEO from "../../layout/seo";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

// markup
const Series = ({ location, data }) => {
  const { t } = useTranslation();

  const series = data.allContentfulPhotoSeries.edges;

  return (
    <main>
      
        <SEO title={t("Photo series")} description={t("Description series")}></SEO>
      <Main location={location}>
        <div className="main-container">
          <div style={{ marginBottom: "2rem" }} className="title-page">
            <Trans>Photo series</Trans>
            <div className="hr"></div>
          </div>
          <Row gutter={[40, 40]}>
            {series.map((serie) => {
              let image = getImage(serie.node.heroImage);

              return (
                <Col xs={24} lg={12}>
                  <Link to={"/photos/series/" + serie.node.slug}>
                    <GatsbyImage
                      objectFit="cover"
                      style={{ cursor: "pointer" }}
                      image={image}
                      alt={serie.node.name}
                      onClick={() => {}}
                    />
                    <p className="serie-title">
                      <a>{serie.node.name}</a>
                    </p>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </div>
      </Main>
    </main>
  );
};

export const pageQuery = graphql`
  query langSeries($language: String!) {
    allContentfulPhotoSeries {
      edges {
        node {
          id
          name
          slug
          heroImage {
            gatsbyImageData(
              width: 1500
              placeholder: DOMINANT_COLOR
              formats: [AUTO, AVIF, WEBP]
            )
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Series;
